import React, { useState, useEffect, useCallback } from "react";
import "./stop-covid.scss";
import "@socialcard/uikit/dist/index.css";
import {
	Input,
	Button,
	Heading,
	Text,
	InfoTable,
	InfoTableItem,
	Checkbox,
	Spacing
} from "@socialcard/uikit";
import { backendServer, cmsServer } from "@constants/common";
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel
} from "react-accessible-accordion";
import CirclePlus from "@components/circle-plus";

const ERROR = "Сервис временно недоступен";

const cardStatuses = {
	error: "Нет доступных ПБ",
	success: "Отложенное пополнение разрешено"
};

const collection = [
	{
		id: 1,
		question:
			"Почему моя карта заблокирована, а меня есть QR-код (о вакцинации от COVID-19, перенесенном заболевании COVID-19, мед.отводе к вакцинации от COVID-19)?",
		answer:
			"Льготные транспортные карты блокируются/разблокируются согласно информации от минздрава РТ о наличии у гражданина QR-кода (о вакцинации от COVID-19 , перенесенном заболевании COVID-19, мед.отводе к вакцинации от COVID-19). Информация обновляется ежедневно. При возникновении вопросов необходимо обращаться на горячую линию минздрава РТ по телефону +7(919)693−05−43."
	},
	{
		id: 2,
		question:
			"Я сегодня сделал вторую прививку, когда моя карта разблокируется?",
		answer:
			"Транспортная карта будет разблокирована оператором автоматизированной оплаты проезда в течение 24 часов после поступления в базу данных сведений о снятии оснований для блокировки."
	},
	{
		id: 3,
		question:
			"У меня есть справка о первой прививке, почему я не могу пользоваться транспортом?",
		answer:
			"Согласно Постановлению КМ РТ от 17.11.2021г. No 1100 с 22.11.2021г. запрещается проезд пассажиров старше 18 лет в общественном транспорте без предъявления QR-кода о вакцинации от COVID-19 , перенесенном заболевании COVID-19, мед.отводе к вакцинации от COVID-19."
	},
	{
		id: 4,
		question:
			"У меня есть справка о мед.отводе. Почему моя карта заблокирована?\n",
		answer:
			"Наличие медицинский противопоказаний к вакцинации должно быть подтверждено персональным QR- кодом."
	}
];

const StopCovid = () => {
	const [value, setValue] = useState("");
	const [status, setStatus] = useState("default");
	const [loading, setLoading] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);
	const [checked, setChecked] = useState(true);
	const [data, setData] = useState(undefined);
	const [error, setError] = useState("");

	const handleChangeInput = value => {
		setValue(value);
	};

	const handleChangeCheckbox = () => {
		setChecked(!checked);
	};

	const replacesStatus = useCallback(value => {
		if (value && typeof value === "string") {
			return value
				.replace(
					cardStatuses.error,
					"Карта заблокирована, справка\u00A0по\u00A0телефону\u00A0+7\u00A0(919)\u00A0693−05−43"
				)
				.replace(cardStatuses.success, "Карта активна");
		}
		return value;
	}, []);

	useEffect(() => {
		if (data && data.comment) {
			if (data.comment === cardStatuses.success) {
				setStatus("success");
			} else if (data.comment === cardStatuses.error) {
				setStatus("error");
			} else {
				setStatus("default");
			}
		}
	}, [data]);

	useEffect(() => {
		if (
			checked &&
			(value.length === 10 || value.length === 16) &&
			Number.isInteger(+value)
		) {
			setFormIsValid(true);
		} else {
			setFormIsValid(false);
		}
	}, [checked, value]);

	const handleSubmit = e => {
		e.preventDefault();
		setError("");
		setData(undefined);

		if (formIsValid) {
			setLoading(true);

			fetch(`${backendServer}/api/getCardBalance`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json;charset=utf-8"
				},
				body: JSON.stringify({ card: value })
			})
				.then(res => {
					if (res.status === 200) {
						return res.json();
					} else {
						setError(ERROR);
					}
				})
				.then(res => {
					const { success, errorText, data } = res;
					if (success) {
						setData(data);
						console.log(res);
					} else {
						setError(errorText || ERROR);
					}
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
					setError(ERROR);
				});
		}
	};

	return (
		<section className="full-width check-covid-blocking">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<Heading
							strong
							gutterBottom
							element="h2"
							text="Проверка льготной транспортной карты на COVID-ограничения"
						/>
					</div>

					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<Text>Введите номер вашей карты</Text>
						<form onSubmit={handleSubmit} className="form-check-covid-blocking">
							<Input
								maxLength={16}
								label="Номер карты"
								value={value}
								name="card"
								onChange={handleChangeInput}
							/>
							{data && (
								<InfoTable
									status={status}
									className="form-check-covid-blocking__result"
								>
									{data.comment && (
										<InfoTableItem
											label="Статус: "
											value={replacesStatus(data.comment)}
										/>
									)}
								</InfoTable>
							)}
							{error && (
								<Spacing bottom>
									<Text color="error">{error}</Text>
								</Spacing>
							)}
							<Checkbox
								id="checkCardBalance"
								name="checkCardBalance"
								checked={checked}
								onChange={handleChangeCheckbox}
							>
								<Text className="form-check-covid-blocking__text">
									Категорически запрещается использование сервиса третьими
									лицами с целью получения конфиденциальной информации.
								</Text>
								<Text className="form-check-covid-blocking__text">
									АО "Социальная карта" оставляет за собой право отказать в
									использовании сервиса без объяснения причин.
								</Text>
							</Checkbox>
							<Button
								loading={loading}
								className="button"
								disabled={!formIsValid}
							>
								Проверить
							</Button>
						</form>
					</div>
				</div>
			</div>

			<Spacing top>
				<div className="grid-container">
					<div className="row">
						<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
							<Heading
								strong
								gutterBottom
								element="h3"
								text="Часто задаваемые вопросы"
							/>
						</div>
						<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
							<Accordion
								allowZeroExpanded
								allowMultipleExpanded
								className="accordion accordion_theme_faq"
							>
								{collection &&
									!!collection.length &&
									collection.map(({ id, question, answer }) => (
										<AccordionItem key={id}>
											<AccordionItemHeading>
												<AccordionItemButton>
													<div className="container">
														<CirclePlus className="circle-plus" />
														<span>{question}</span>
													</div>
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>{answer}</AccordionItemPanel>
										</AccordionItem>
									))}
							</Accordion>
						</div>
					</div>
				</div>
			</Spacing>
		</section>
	);
};

export default StopCovid;
